import React from 'react';
import styles from "../Styles/Footer.module.css";
import { Link } from 'react-router-dom';
import Logo from "../Assets/images/logo.svg"
import { faTelegram, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Footer = () => {
    return (
        <div className={styles.container}>
            <div className={styles.contents}>
                <div className={styles.matfa}>
                    <div className={styles.matfaLogo}>
                    <h3> مهد توسعه فناوری آلاو  - متفا</h3>
                    <img src={Logo} alt='logo' />
                    </div>
                    <div className={styles.copyRight}>
                    <p>   کلیه حقوق این وب سایت محفوط می باشد 2023&copy;</p>
                    </div>
                    <div className={styles.socialMedia}>
                    <div className={styles.socialIcon}>
                    <FontAwesomeIcon  className={styles.fa_icon} style={{fontSize:"35px" , color:"#fff"}} icon={faTelegram} />
                    </div>
                    <div className={styles.socialIcon}>
                    <FontAwesomeIcon  className={styles.fa_icon} style={{fontSize:"35px" , color:"#fff"}} icon={faInstagram} />
                    </div>
                    <div className={styles.socialIcon}>
                    <FontAwesomeIcon  className={styles.fa_icon} style={{fontSize:"35px" , color:"#fff"}} icon={faTwitter} />
                    </div>
                    </div>
                </div>

                <div className={styles.info} style={{margin:"0px 20px"}}>

                    <div className={styles.infoh}>

                        <h3>اطلاعات ما</h3>
                    </div>

                    <ul className={styles.list1}>
                        <li><Link to="/">درباره ما</Link> </li>
                        <li><Link to="/">بلاگ</Link></li>
                    </ul>
                    <ul className={styles.list2}>
                        <li><Link to="/">تماس با ما</Link> </li>
                        <li><Link to="/">حریم شخصی</Link></li>
                    </ul>
                    <ul className={styles.list}>
                        <li><Link to="/">پشتیبانی</Link> </li>
                        <li><Link to="/">شرایط و قوانین</Link></li>
                    </ul>
                    
                </div>

                <div className={styles.info}>

                    <div className={styles.infoh}>

                        <h3>آدرس ما</h3>
                    </div>
                    <div>
                        <p>تهران، میدان رسالت ، خیابان کرمان شمالی ، نبش خیابان صافی ، ساختمان مادر  ، واحد شانزده</p>
                        {/* <p>۰۲۱-۸۸۵۲۳۰۰۴ - ۰۲۱-۸۸۵۲۳۰۰۶ </p> */}
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default Footer;