import React from 'react';
import Menu from '../Components/Menu.js';
import Owlcarousel from '../Components/Owlcarousel.js';
import Tools from '../Components/Tools.js';
import Productscarousel from '../Components/Productscarousel.js';
import Counter from '../Components/Counter.js';
import MySection from '../Components/Mysection.js';
import Video from '../Components/Video.js';
// import Aboutuscard from '../Components/Aboutuscard.js';
import Aboutus from '../Components/Aboutus.js';
import Footer from '../Components/Footer.js';
import BackToTop from '../Components/BackToTop.js';

const Home = () => {
    return (
        <div>
            
            <Menu />
            <Owlcarousel />
            <Tools />
            <Productscarousel />
            <MySection />
            {/* <Aboutuscard /> */}
            <Video />
            <Aboutus/>
            <Counter />
            <Footer />
            <BackToTop />
        </div>
    );
};

export default Home;