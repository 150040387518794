import React from 'react';
import styles from "../Styles/Videocard.module.css"
const Videocard = ({ title, videoHtml }) => (
    <div className={styles.product_card}>
    <h3>{title}</h3>
    <div className={styles.video_container} dangerouslySetInnerHTML={{ __html: videoHtml }}></div>
  </div>
);

export default Videocard;
