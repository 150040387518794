import { useState } from "react";
import { Link, Element } from 'react-scroll';
import styles from "../Styles/Menu.module.css";

const Menu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const overlay = document.querySelector(styles.overlay);
  if (overlay) {
    if (menuOpen) {
      overlay.style.display = "none";
    } else {
      overlay.style.display = "block";
    }
  }

  if (menuOpen) {
    document.documentElement.classList.add(styles.noScroll);
    document.body.classList.add(styles.noScroll);
  } else {
    document.documentElement.classList.remove(styles.noScroll);
    document.body.classList.remove(styles.noScroll);
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div id="overlay" className={`${styles.overlay} ${menuOpen ? styles.overlayVisible : ''}`} onClick={toggleMenu}></div>

      <header className={styles.header}>
        <nav>
          <div className={styles.logo}>
            Matfa
          </div>

          <ul className={`${styles.menu} ${menuOpen ? styles.open : ''}`}>
            <Link to="section1" spy={true} smooth={true} duration={500}><li>صفحه اصلی</li></Link>
            <Link to="section2" spy={true} smooth={true} duration={500}><li>محصولات</li></Link>
            <Link to="section3" spy={true} smooth={true} duration={500}><li>مالتی مدیا</li></Link>
            <Link to="section4" spy={true} smooth={true} duration={500}><li>درباره ما</li></Link>
          </ul>

          <div className={`${styles.hamburger} ${menuOpen ? styles.open : ''}`} onClick={toggleMenu}>
            <div className={`${styles.bar} ${menuOpen ? styles.open : ''}`}></div>
            <div className={`${styles.bar} ${menuOpen ? styles.open : ''}`}></div>
            <div className={`${styles.bar} ${menuOpen ? styles.open : ''}`}></div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Menu;
