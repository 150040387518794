import React from 'react';
import '../Styles/ProductCard.css'; // Import the CSS file
const Productscard = ({ title,badg, price, imageUrl }) => {
    return (
        <div className="product-card">
        <div className="card-content">
          <img src={imageUrl} alt={title} />
          <h3>{title}</h3>
          <p className="badg">{badg}</p>
          <p >{price}</p>
          
        </div>
      </div>
    );
  };

export default Productscard;