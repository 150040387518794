import React from 'react';
import {Element} from 'react-scroll';
import styles from "../Styles/Aboutus.module.css"
const Aboutus = () => {

    return (

        <Element name="section4" className="element">
        <div style={{  margin:" 0px 0px 100px 0px"}}>
          <div className={styles.container} style={{  padding:" 0 12px 0"}}>
            <div style={{ marginBottom: '20px' }}>
              <div className={styles.headermtn}>
                <h2>درباره ما</h2>
              </div>
            </div>
          </div>
          <div className={styles.bodytextfield}>
          <div className={styles.bodytext}>
            <p>متفا! یک مجموعه دانش محور و دانش بنیان با سرمایه منحصر به فرد نیروی انسانی با تجربه و متعهد می باشد. در حال حاضر پنج واحد توسعه نرم افزار ، طراحی و توسعه الکترونیک ، مکانیک ، هوافضا و رباتیک بصورت مستقل با ظرفیت کامل در حال توسعه محصولات مورد تقاضای مشتریان دولتی و خصوصی می باشد. متفا با پازنده سال سابقه فعالیت تحقیقاتی و صنعتی بصورت مستمر در حال بروزرسانی و پشتیبانی از محصولات تولیدی خود در سطح کشور می باشد. در متفا سعی به ایجاد زنجیره تولید از طراحی نمونه محصول تا تولید نمونه نیمه صنعتی و صنعتی ، توسعه درایور نرم افزاری ، توسعه اپلیکیشن بهره برداری بصورت موازی با توسعه سخت افزار بومی  گردیده است. کلیه ابعاد یک محصول از زوایای نرم افزار ، مکانیک و الکترونیک در متفا طراحی ، نمونه سازی و تولید می گردند. </p>
          </div>
          </div>
        </div>
        </Element>
  );
}; 
    export default Aboutus;