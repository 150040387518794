import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import styles from '../Styles/Counter.module.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faPercent } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(faPlus); // Add the FontAwesome icon

const CounterItem = ({ count, text }) => (
  <div className={styles.counter_items}>
    <span>
      {text === 'پروژه در حال اجرا' && (
        <FontAwesomeIcon style={{ fontSize: "42px", color: "var(--main-color)" }} icon={faPlus} />
      )}{' '}
      {text === 'رضایت مشتریان' && (
        <FontAwesomeIcon style={{ fontSize: "42px", color: "var(--main-color)" }} icon={faPercent} />
      )}{' '}
      <CountUp end={count} duration={3} />
    </span>
    <p>{text}</p>
  </div>
);

const Counter = () => {
  const defaultCounts = {
    projectsDone: 250,
    projectsInProgress: 8,
    customerSatisfaction: 90,
    yearsOfExperience: 15,
    humanResources: 17,
  };

  const [counts, setCounts] = useState(defaultCounts);

  useEffect(() => {
    const storedCounts = JSON.parse(localStorage.getItem('counts'));

    if (storedCounts) {
      setCounts(storedCounts);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('counts', JSON.stringify(counts));
  }, [counts]);

  return (
    <div className={styles.body}>
      <div className={styles.container}>
        <div className={styles.counter}>
          <VisibilitySensor
            onChange={(isVisible) => {
              if (isVisible) {
                // Start the count animation when the component becomes visible
                setCounts({
                  projectsDone: 250,
                  projectsInProgress: 8,
                  customerSatisfaction: 90,
                  yearsOfExperience: 15,
                  humanResources: 35,
                });
              }
            }}
          >
            {({ isVisible }) => (
              <CounterItem count={isVisible ? counts.projectsDone : 0} text="پروژه انجام شده" />
            )}
          </VisibilitySensor>
          <VisibilitySensor
            onChange={(isVisible) => {
              if (isVisible) {
                // Start the count animation when the component becomes visible
                setCounts({
                  projectsDone: 250,
                  projectsInProgress: 8,
                  customerSatisfaction: 90,
                  yearsOfExperience: 15,
                  humanResources: 35,
                });
              }
            }}
          >
            {({ isVisible }) => (
              <CounterItem count={isVisible ? counts.projectsInProgress : 0} text="پروژه در حال اجرا" />
            )}
          </VisibilitySensor>
          <VisibilitySensor
            onChange={(isVisible) => {
              if (isVisible) {
                // Start the count animation when the component becomes visible
                setCounts({
                  projectsDone: 250,
                  projectsInProgress: 8,
                  customerSatisfaction: 90,
                  yearsOfExperience: 15,
                  humanResources: 35,
                });
              }
            }}
          >
            {({ isVisible }) => (
              <CounterItem count={isVisible ? counts.customerSatisfaction : 0} text="رضایت مشتریان" />
            )}
          </VisibilitySensor>
          <VisibilitySensor
            onChange={(isVisible) => {
              if (isVisible) {
                // Start the count animation when the component becomes visible
                setCounts({
                  projectsDone: 250,
                  projectsInProgress: 8,
                  customerSatisfaction: 90,
                  yearsOfExperience: 15,
                  humanResources: 35,
                });
              }
            }}
          >
            {({ isVisible }) => (
              <CounterItem count={isVisible ? counts.yearsOfExperience : 0} text="سال تجربه کاری" />
            )}
          </VisibilitySensor>
          <VisibilitySensor
            onChange={(isVisible) => {
              if (isVisible) {
                // Start the count animation when the component becomes visible
                setCounts({
                  projectsDone: 250,
                  projectsInProgress: 8,
                  customerSatisfaction: 90,
                  yearsOfExperience: 15,
                  humanResources: 35,
                });
              }
            }}
          >
            {({ isVisible }) => (
              <CounterItem count={isVisible ? counts.humanResources : 0} text=" نیروی انسانی متخصص" />
            )}
          </VisibilitySensor>
        </div>
      </div>
    </div>
  );
};

export default Counter;
