import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ProductCard from './Productscard';
import { Link, Element } from 'react-scroll';
import Passport from '../Assets/images/pasport.png';
import Printerpassport from '../Assets/images/printerpasport.png';
import Cubsatpod from '../Assets/images/Cubsat Pod.png';
import Dgistream from '../Assets/images/DJI Stream.png';
import Gps1 from '../Assets/images/gps1.png';
import Gps2 from '../Assets/images/gps2.png';
import Gps3 from '../Assets/images/gps3.png';

const products = [
  {
    title: 'صدور گذرنامه',
    // price: '۶۰۰,۰۰۰,۰۰۰',
    // badg:'تومان',
    imageUrl: Passport,
  },
  {
    title: 'پرینتر گذرنامه',
    // price: '۶۰۰,۰۰۰,۰۰۰',
    // badg:'تومان',
    imageUrl: Printerpassport,
  },
  {
    title: 'کیوبست پاد',
    // price: '۶۳۰,۰۰۰,۰۰۰',
    // badg:'تومان',
    imageUrl: Cubsatpod,
  },
  {
    title: 'دیجی استریم',
    // price: '۸۸۰,۰۰۰,۰۰۰',
    // badg:'تومان',
    imageUrl: Dgistream,
  },
  {
    title: 'ردیاب مغناطیسی',
    // price: '۱۲۱,۰۰۰,۰۰۰',
    // badg:'تومان',
    imageUrl: Gps1,
  },
  {
    title: 'ردیاب خودرویی',
    // price: '۳۲,۹۰۰,۰۰۰',
    // badg:'تومان',
    imageUrl: Gps2,
  },
  {
    title: 'ردیاب هوشمند',
    // price: '۶۸,۰۰۰,۰۰۰',
    // badg:'تومان',
    imageUrl: Gps3,
  },
  // Add more product objects as needed
];

const ProductCarousel = () => {
  const options = {
    margin: 10,
    loop: false,
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 2,
      },
      800: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1200: {
        items: 5,
      },
    },
  };

  return (
    <Element name="section2" className="element">
    <div>

      <div className="container" style={{padding:" 0 12px 0"}} >      
        <div style={{marginBottom: "20px"}} >      
        <div className="headermtn">      
        <h2>نمونه محصولات</h2>
        {/* <p>برای دیدن تمامی محصولات مورد نیاز خود نگران نباشید چون متفا دقدقه شما را بر طرف می کند</p>  */}
        </div>
        {/* <div className="btn">
                    <button>نمایش نمونه کارها</button>
        </div>         */}
        </div>  
    </div>  

    <div className="product-carousel">

      <OwlCarousel className="owl-theme" {...options}>
        {products.map((product, index) => (
          <div key={index} className="item" style={{height:"auto"}}>
            <ProductCard {...product} />
          </div>
        ))}
      </OwlCarousel>

    </div>
    </div>
    </Element>
  );
};

export default ProductCarousel;
