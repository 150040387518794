import React from 'react';
import styles from "../Styles/Toolcard.module.css";
const Toolcard = (props) => {
    const {image , name} = props;
    return (
        <div className={styles.body}>
                <div className={styles.container}>
                    <img src={image} alt='cardimg' />
                <h3> {name} </h3>

            </div>
        </div>
    );
};

export default Toolcard;