import React, { useState } from 'react';
import styles from "../Styles/BackToTop.module.css"
const BackToTop = () => {
  const [showButton, setShowButton] = useState(false);

  // Scroll to the top of the page when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Show the button when scrolling down and hide it when at the top
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  // Add a scroll event listener to control button visibility
  window.addEventListener('scroll', handleScroll);

  return (
    <div className={styles.back_to_top}>
      {showButton && (
        <button onClick={scrollToTop} title="Back to Top">
          ↑
        </button>
      )}
    </div>
  );
};

export default BackToTop;
