import React, { useState } from 'react';
import styles from '../Styles/Mysection.module.css';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Services1 from "../Assets/images/services1.jpg"
import Services2 from "../Assets/images/services2.jpg"
import Services3 from "../Assets/images/services3.jpg"
import Services4 from "../Assets/images/services4.jpg"
const Mysection = () => {
    const [containerBackground, setContainerBackground] = useState(Services1);

    const handleCardHover = (backgroundImage) => {
      setContainerBackground(backgroundImage);
    };
  
    return (
      <div className={styles.container} style={{ backgroundImage: `url(${containerBackground})` }}>
        <div className={`${styles.card} ${styles.card1}`} onMouseEnter={() => handleCardHover(Services1)}>

            <div className={styles.bodyT}>
            <div className={styles.features}>
           
                <span>بخش اول</span>
                <h3>راهکارهای ردیابی</h3>
                <p>
                    طیف متنوع سخت افزارهای ردیابی به همراه اپلیکیشن کنترل تحت وب و سامانه کاربری مبتنی بر اندروید
                </p>
    

            </div>
            <div className={styles.btn}>
                <span>
                <FontAwesomeIcon  className={styles.fa_icon} style={{fontSize:"25px" , color:"#fff"}} icon={faArrowLeft} />
                </span>
                <p>
                    ادامه مطلب
                </p>
            </div>
            </div>
        </div>

        <div className={`${styles.card} ${styles.card2}`} onMouseEnter={() => handleCardHover(Services2)}>
        <div className={styles.bodyT}>
        <div className={styles.features}>
   
                <span>بخش دوم</span>
                <h3>راهکارهای چاپ بوکلت</h3>
                <p> طراحی و تولید چاپگر بوکلت جهت چاپ استاندارد پاسپورت و شناسنامه</p>
   
        </div>
        <div className={styles.btn}>
                <span>
                <FontAwesomeIcon  className={styles.fa_icon} style={{fontSize:"25px" , color:"#fff"}} icon={faArrowLeft} />
                </span>
                <p>
                    ادامه مطلب
                </p>
            </div>
            </div>
        </div>
        <div className={`${styles.card} ${styles.card3}`} onMouseEnter={() => handleCardHover(Services3)}>
        <div className={styles.bodyT}>
        <div className={styles.features}>
    
                <span>بخش سوم</span>
                <h3>رباتیک و هوش مصنوعی</h3>
                <p> توسعه ربات های چهارپا ، دو پا و تجهیزات هواپایه هوشمند مبتنی بر کنترلرهای هوشمند اختصاصی متفا</p>
            </div>
            <div className={styles.btn}>
                <span>
                <FontAwesomeIcon  className={styles.fa_icon} style={{fontSize:"25px" , color:"#fff"}} icon={faArrowLeft} />
                </span>
                <p>
                    ادامه مطلب
                </p>
            </div>
            </div>
        </div>
        <div className={`${styles.card} ${styles.card4}`} onMouseEnter={() => handleCardHover(Services4)}>
        <div className={styles.bodyT}>
        <div className={styles.features}>
  
                <span>بخش چهارم</span>
                <h3>پیام رسان سازمانی </h3>
                <p>توسعه از ابتدای پیام رسان تخصصی سازمانی با ویژگی های منحصر به فرد امنیتی ، مبتنی بر پروتکل اختصاصی متفا
                </p>
      
            </div>
                        <div className={styles.btn}>
                <span>
                <FontAwesomeIcon  className={styles.fa_icon} style={{fontSize:"25px" , color:"#fff"}} icon={faArrowLeft} />
                </span>
                <p>
                    ادامه مطلب
                </p>
            </div>
            </div>
        </div>
      </div>
    );
  };

export default Mysection;