import React, { useState } from 'react';
import Toolcard from './Toolcard';
import styles from '../Styles/Toolcard.module.css';
import Info1 from '../Assets/images/info1.jpg';
import Info2 from '../Assets/images/Info2.jpg';
import Info3 from '../Assets/images/Info3.jpg';
import Info4 from '../Assets/images/Info4.jpg';
import Info5 from '../Assets/images/Info5.jpg';

const toolData = [
    {
        image: require('../Assets/images/hava faza.svg').default,
        name: 'واحد هوا فضا',
    },
    {
        image: require('../Assets/images/industry.61bf61c.svg').default,
        name: 'واحد پردازش داده',
    },
    {
        image: require('../Assets/images/robatic.svg').default,
        name: 'واحد رباتیک',
    },
    {
        image: require('../Assets/images/electronic.svg').default,
        name: 'واحد الکترونیک',
    },
    {
        image: require('../Assets/images/software-engineering-icon.svg').default,
        name: 'واحد توسعه نرم افزار',
    },
];

const infoImages = {
    'واحد هوا فضا': Info5,
    'واحد پردازش داده': Info2,
    'واحد رباتیک': Info4,
    'واحد الکترونیک': Info1,
    'واحد توسعه نرم افزار': Info3,
};

const Tools = () => {
    const [hoveredTool, setHoveredTool] = useState(null);

    return (
        <div>
            <div className={styles.body}>
                <div style={{ marginBottom: '20px' }}>
                    <div className={styles.headermtn}>
                        <h2>دامنه های تخصصی فعالیت متفا</h2>
                    </div>
                </div>
                <div className={styles.tools}>
                    <div className={styles.tools_items}>
                        {toolData.map((tool, index) => (
                            <div
                                key={index}
                                className={styles.toolContainer}
                                onMouseEnter={() => setHoveredTool(tool.name)}
                                onMouseLeave={() => setHoveredTool(null)}
                            >
                                <Toolcard className={styles.tools_items_card} image={tool.image} name={tool.name} />
                                <img
                                    className={`${styles.Info} ${hoveredTool === tool.name ? styles.InfoVisible : ''}`}
                                    src={infoImages[tool.name]}
                                    alt=''
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tools;
