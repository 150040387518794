import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Videocard from './Videocard';
import { Link, Element } from 'react-scroll';
import "../Styles/Videocarousel.css"

const videos = [
  {
    
    // title: 'ماتریس 30',
    videoHtml: '<style>.h_iframe-aparat_embed_frame{position:relative;}.h_iframe-aparat_embed_frame .ratio{display:block;width:100%;height:auto;}.h_iframe-aparat_embed_frame iframe{position:absolute;top:0;left:0;width:100%;height:100%;}</style><div class="h_iframe-aparat_embed_frame"><span style="display: block;padding-top: 57%"></span><iframe src="https://www.aparat.com/video/video/embed/videohash/L4Ycz/vt/frame"  allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>',
  },
  {
    // title: 'ماتریس 30',
    videoHtml: '<style>.h_iframe-aparat_embed_frame{position:relative;}.h_iframe-aparat_embed_frame .ratio{display:block;width:100%;height:auto;}.h_iframe-aparat_embed_frame iframe{position:absolute;top:0;left:0;width:100%;height:100%;}</style><div class="h_iframe-aparat_embed_frame"><span style="display: block;padding-top: 57%"></span><iframe src="https://www.aparat.com/video/video/embed/videohash/DaYqy/vt/frame" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>',
  },
  {
    // title: 'ماتریس 30',
    videoHtml: '<style>.h_iframe-aparat_embed_frame{position:relative;}.h_iframe-aparat_embed_frame .ratio{display:block;width:100%;height:auto;}.h_iframe-aparat_embed_frame iframe{position:absolute;top:0;left:0;width:100%;height:100%;}</style><div class="h_iframe-aparat_embed_frame"><span style="display: block;padding-top: 57%"></span><iframe src="https://www.aparat.com/video/video/embed/videohash/DaYqy/vt/frame" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>',
  },
  {
    // title: 'ماتریس 30',
    videoHtml: '<style>.h_iframe-aparat_embed_frame{position:relative;}.h_iframe-aparat_embed_frame .ratio{display:block;width:100%;height:auto;}.h_iframe-aparat_embed_frame iframe{position:absolute;top:0;left:0;width:100%;height:100%;}</style><div class="h_iframe-aparat_embed_frame"><span style="display: block;padding-top: 57%"></span><iframe src="https://www.aparat.com/video/video/embed/videohash/DaYqy/vt/frame" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>',
  },
  {
    // title: 'ماتریس 30',
    videoHtml: '<style>.h_iframe-aparat_embed_frame{position:relative;}.h_iframe-aparat_embed_frame .ratio{display:block;width:100%;height:auto;}.h_iframe-aparat_embed_frame iframe{position:absolute;top:0;left:0;width:100%;height:100%;}</style><div class="h_iframe-aparat_embed_frame"><span style="display: block;padding-top: 57%"></span><iframe src="https://www.aparat.com/video/video/embed/videohash/DaYqy/vt/frame" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>',
  },
  // Add more product objects with iframe elements for the video content
];

const Video = () => {
  const options = {
    margin: 10,
    nav:true,
    loop: false,
    responsive: {
      0: {
        items: 1,
      },
      800: {
        items: 2,
      },
      1600: {
        items: 3,
      },
    },
  };

  return (
    <Element name="section3" className="element">
    <div style={{  margin:" 0px 0px 0px 0px"}}>
      <div className="container" style={{  padding:" 0 12px 0"}}>
        <div style={{ marginBottom: '20px' }}>
          <div className="headermtn">
            <h2>مالتی مدیا</h2>
            {/* <p>برای دیدن تمامی ویدیو ها کافیست برروی هر یک از ویدیو ها کلیک کنید و مشکلات خود را برطرف کنید</p> */}
          </div>
          {/* <div className="btn">
            <button>نمایش ویدیو ها</button>
          </div> */}
        </div>
      </div>

      <div className="video-carousel">
        <OwlCarousel className="owl-theme1" {...options}>
          {videos.map((video, index) => (
            <div key={index} className="item" style={{ height: 'auto' }}>
              <Videocard  {...video} />
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
    </Element>
  );
};

export default Video;
