import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "../Styles/Owlcarousel.css";
import { Link, Element } from 'react-scroll';
import Img1 from "../Assets/images/achive.jpg";
import Img2 from "../Assets/images/robatic.png";
import Img3 from "../Assets/images/massenger.jpg";

const options = {
  margin: 10,
  loop: true,
  nav:true,
  dots:false,
  autoplay: false,
  autoplayTimeout: 5000,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const Owlcarousel = () => {
  return (

    <Element name="section1" className="element">
    <div className="C_Owlcarousel">

      <OwlCarousel className="owl-theme" {...options}>
        
        <div className="item">
          <div className="image-container">
            <img src={Img1} alt="Image 1" />
            <div className="image-text">بیش از یک دهه فعالیت موفق تحقیقاتی و صنعتی</div>
            {/* <div className="image-text1">خدمات پیشرفته حفاری  با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.</div> */}
          </div>
        </div>
        <div className="item">
          <div className="image-container">
            <img src={Img2} alt="Image 2" />
            <div className="image-text">همگام با آخرین پیشرفت های رباتیک دنیا </div>
            {/* <div className="image-text1">ارائه تجهیزات و خدمات کامل حفاری چاه </div> */}
          </div>
        </div>
        <div className="item">
          <div className="image-container">
            <img src={Img3} alt="Image 3" />
            <div className="image-text"> توسعه پیام رسان و اپلیکیشن های سازمانی</div>
            {/* <div className="image-text1">ارائه تجهیزات و خدمات کامل حفاری چاه </div> */}
          </div>
        </div>

      </OwlCarousel>

    </div>
    </Element>
  );
};

export default Owlcarousel;
